<template>
  <div class="outOrder">
    <div class="select">
      <p>时间选择：</p>
      <el-radio-group v-model="radio" @change="changeRadio">
        <el-radio v-model="radio" :label="1">本月</el-radio>
        <el-radio v-model="radio" :label="2">近30天</el-radio>
        <el-radio v-model="radio" :label="3">自定义</el-radio>
      </el-radio-group>
    </div>
    <div v-show="radio == 3" class="date">
      <el-date-picker
        v-model="date"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        @change="changeDate"
        format="yyyy 年 MM 月 dd 日"
        value-format="yyyy-MM-dd"
      >
      </el-date-picker>
    </div>
    <el-button type="primary" @click="down" class="btn">下载</el-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      radio: 1,
      date: [],
    };
  },

  components: {},

  computed: {},

  mounted() {},

  methods: {
    down() {
      let _this = this
      let user = JSON.parse(window.localStorage.getItem("user"))
      window.location.href = `https://www.kun006.top/download?user_id=${user.id}&user_level=${user.level}&select_type=${_this.radio}&date_scope=["${_this.date[0]}","${_this.date[1]}"]`
    },
    changeDate(e) {
      this.date = e
    },
    changeRadio(e){
      this.radio = e
      if(e!=3){
        this.date = []
      }
    }
  },
};
</script>
<style lang='less' scoped>
.btn {
  margin-top: 20px;
}
.select {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.outOrder {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>